<template>
  <div class="mx-8">
    <v-subheader class="py-0 d-flex justify-space-between">
      <h3>{{ satker }}</h3>

      <v-dialog v-model="dialogAdd" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="success" v-bind="attrs" v-on="on"> Tambah Data </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 success white--text">
            Tambah Data
          </v-card-title>

          <br />

          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-text-field
                label="Satuan Kerja"
                v-model="label"
                :rules="requiredRules"
                required
              ></v-text-field>
              <v-textarea
                label="Keterangan"
                v-model="keterangan"
                hide-details
              ></v-textarea>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogAdd = false">
              Batal
            </v-btn>
            <v-btn color="primary" text @click="save()"> Simpan </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-subheader>

    <div class="mb-2"></div>

    <v-text-field
      label="Satuan Kerja"
      outlined
      v-model="keyword"
      hide-details
      append-icon="mdi-magnify"
      class="mx-4"
      @change="init()"
    ></v-text-field>

    <div class="mb-4"></div>

    <v-data-table
      :headers="headers"
      :items="listSatker.data"
      hide-default-footer
      disable-sort
    >
      <template v-slot:item.isEnable="{ item }">
        <span v-if="item.isEnable == true"
          ><v-chip class="success" small>YA</v-chip></span
        >
        <span v-else><v-chip class="error" small>TIDAK</v-chip></span>
      </template>

      <template v-slot:no-data>
        <label>Tidak ada data</label>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="error"
              class="mr-2"
              @click="showDialogEdit(item)"
              >mdi-pencil-box</v-icon
            >
          </template>
          <span>Edit</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="error"
              @click="showDialogDelete(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Hapus</span>
        </v-tooltip>
      </template>

      <template v-slot:footer>
        <v-divider class="mt-6"></v-divider>
        <v-row class="mt-3 mb-3" align="center" justify="end">
          <span class="caption">Limit:</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn dark text color="primary" class="ml-2" v-on="on">
                {{ limit }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in limitArray"
                :key="index"
                @click="updateLimit(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <span class="caption ml-4 mr-4"
            >Total Data: {{ listSatker.jumlahdata }}</span
          >
          <span class="caption ml-4 mr-4"
            >Page {{ page }} of {{ listSatker.jumlahhalaman }}</span
          >
          <v-btn small color="primary" class="mr-4" @click="prevPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn small color="primary" class="mr-4" @click="nextPage">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogEdit" width="500">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Edit Data
        </v-card-title>

        <br />

        <v-card-text>
          <v-form ref="form">
            <v-text-field
              label="Satuan Kerja"
              v-model="dataEdit.label"
            ></v-text-field>
            <v-textarea
              label="Keterangan"
              v-model="dataEdit.keterangan"
            ></v-textarea>
            <v-select
              label="Aktif"
              outlined
              v-model="dataEdit.isEnable"
              :items="statusOptions"
              hide-details
            ></v-select>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="edit()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" width="500">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Hapus Data
        </v-card-title>

        <br />

        <v-card-text
          >Apakah anda yakin menghapus data
          {{ dataDelete.label }} ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete = false">
            Tidak
          </v-btn>
          <v-btn color="primary" text @click="deleteSatker()"> Ya </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="success" timeout="1000">
      {{ snackbarText }}

      <template v-slot:action>
        <v-btn fab x-small color="white" @click="snackbar = false">
          <v-icon color="error">mdi-close-thick</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "No.", value: "nomor", width: "70px" },
        { text: "Satuan Kerja", value: "label" },
        { text: "Aktif", value: "isEnable" },
        { text: "Keterangan", value: "keterangan" },
        { text: "Aksi", value: "actions" },
      ],
      page: 1,
      limit: 12,
      limitArray: [12, 24, 48],
      keyword: "",
      kelompok: this.$route.params.id,
      satker: this.$route.params.satker,
      params: "",
      dialogAdd: false,
      dialogEdit: false,
      dialogDelete: false,
      label: "",
      keterangan: "",
      dataEdit: {
        id: "",
        label: "",
        keterangan: "",
        isEnable: "",
      },
      dataDelete: {
        id: "",
        label: "",
      },
      statusOptions: [
        { text: "YA", value: "true" },
        { text: "TIDAK", value: "false" },
      ],
      valid: true,
      requiredRules: [(v) => !!v || "Data harus diisi"],
      snackbar: false,
      snackbarText: "",
    };
  },
  watch: {
    dialogAdd() {
      if (!this.dialogAdd) {
        this.$refs.form.reset();
        this.label = "";
        this.keterangan = "";
      }
    },
  },
  computed: {
    listSatker() {
      return this.$store.getters.listSatker;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.params = {
        page: this.page,
        limit: this.limit,
        label: this.keyword,
        kelompok: this.kelompok,
      };
      this.$store.dispatch("getListSatker", this.params);
    },

    nextPage() {
      if (this.page + 1 <= this.listSatker.jumlahhalaman) {
        this.page += 1;
        this.init();
      }
    },

    prevPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.init();
      }
    },

    updateLimit(number) {
      this.limit = number;
      this.init();
    },

    save() {
      this.$refs.form.validate();
      if (this.label != "") {
        if (this.valid) {
          const data = {
            kelompok: this.kelompok,
            label: this.label,
            keterangan: this.keterangan,
          };
          this.$store.dispatch("addSatker", data).then((value) => {
            this.snackbar = true;
            this.snackbarText = value.pesan;
            if (value.hasil == "success") {
              this.init();
              this.dialogAdd = false;
            }
          });
        }
      } else {
        this.snackbar = true;
        this.snackbarText = "Satuan Kerja tidak boleh kosong";
      }
    },

    showDialogEdit(item) {
      this.dialogEdit = true;
      this.dataEdit.id = item._id;
      this.dataEdit.label = item.label;
      this.dataEdit.keterangan = item.keterangan;
      this.dataEdit.isEnable = item.isEnable.toString();
    },
    edit() {
      const data = {
        id: this.dataEdit.id,
        label: this.dataEdit.label,
        keterangan: this.dataEdit.keterangan,
        isEnable: this.dataEdit.isEnable,
      };
      this.$store.dispatch("editSatker", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogEdit = false;
        }
      });
    },
    showDialogDelete(item) {
      this.dialogDelete = true;
      this.dataDelete.id = item._id;
      this.dataDelete.label = item.label;
    },

    deleteSatker() {
      const data = { id: this.dataDelete.id };
      this.$store.dispatch("deleteSatker", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        this.dialogDelete = false;
        if (value.hasil == "success") {
          this.init();
        }
      });
    },
  },
};
</script>